--root {
}

@font-face {
  font-family: "cinzel";
  src: url("../public/fonts/Cinzel-VariableFont_wght.ttf") format("truetype");
}

.category,
.search,
.language {
  background-color: white;
}

.navigation {
  background-color: #010000;
  border-bottom: 1px solid #1e6bb3;
}

.logo {
  font-family: "cinzel";
}

.logo {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
}

.bg2 {
  background-color: #282c3c;
}

.addToCart {
  margin-top: 20px;
  font-size: 20px;
  font-family: "cinzel";
  font-weight: bold;
  padding: 5px 82px;
  color: #f1eaea;
  background-color: #d3c613;
  border: none;
}

#products {
  margin-top: 40px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}
.horizontalTable {
  overflow-x: scroll;
}

.table-container {
  height: 100vh;
  overflow-y: scroll;
  border-top: 1px solid black;
}
.form-select:focus {
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
}
.whatsapp {
  color: white;
  font-size: 27px;
  font-weight: bold;
}

.cart {
  background-color: #f1eaea;
}
.purchase {
  background-color: green;
  font-weight: bold;
}

main {
  min-height: 80vh;
}

.subtotal {
  height: min-content;
  width: 300px;
}

.disabledLink {
  pointer-events: none;
}

.black {
  color: black;
}

.inline {
  display: inline-block;
  margin-left: auto;
}

.minHeight {
  height: min-content;
}

.minWidth {
  width: 200px;
}

.textInput {
  max-width: 500px;
}

.width {
  min-width: 900;
}

.maxWidth {
  max-width: 600px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cardBody {
  padding: 0;
}

.selected-row {
  background-color: rgb(105, 201, 105);
  color: rgb(0, 0, 0);
}

.badge:after {
  content: attr(value);
  font-size: 16px;
  color: #fff;
  background: red;
  border-radius: 50%;
  padding: 3px 8px;
  position: relative;
  left: -8px;
  top: -10px;
  opacity: 0.9;
}

.div4 {
  display: none;
}

@media (max-width: 768px) {
  .div2 {
    display: none;
  }

  .div4 {
    display: block;
  }
  .shoppingCart {
    font-size: 22px;
  }
  .orderHere {
    font-size: 15px;
  }
  .detail {
    font-size: 16px;
  }

  #products {
    margin-top: 40px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
  .imageFields {
    display: grid;
    grid-gap: 1px;
    grid-template-columns: 1fr 1fr;
  }
}

.theme {
  color: #021118;
}
.them2 {
  color: #d3c613;
}

.theme:hover {
  color: #d3c613;
}

.green {
  color: rgb(0, 255, 0);
  text-shadow: 2px 1px 2px #000000;
  padding-right: 2px;
}

.yellow {
  font-size: 17px;
  text-shadow: 2px 1px 1px #000000;
  color: rgb(247, 247, 19);
}
.red {
  text-shadow: 2px 1px 2px #000000;
  color: rgb(255, 0, 0);
}

.product {
  padding: 1px;
}

.quantityInput {
  border: 2px solid #1e6bb3;
  border-top: 0px;
  border-bottom: 0px;
  width: 35px;
}

.increaseButton {
  border: 2px solid #1e6bb3;
  border-bottom: 0px;
  width: 35px;
}

.decreaseButton {
  border: 2px solid #1e6bb3;
  border-top: 0px;
  width: 35px;
}
